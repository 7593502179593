.perizie-table{
  width: 100%;
  text-align: center;
}
.perizie-table > tbody > tr > td,
.perizie-table > thead > tr > th{
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.perizie-table > tbody > tr,
.perizie-table > thead > tr {
  border-bottom: 1px solid #ddd;
}
.perizie-items > ion-col {
  color: darkgray;
  font-size: 12px;
  max-width: 120px;
}
.perizie-items > ion-col > p {
  color: #000;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h2.title{
  font-size: 22px;
  color: var(--perizia-card-title-color);
}
.hide{
  visibility: hidden;
}
.show{
  visibility: visible;
}
