@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Regular.ttf');
}
ion-content{
    font-family:'Open Sans' !important;
    font-size:1.1em;
}
body {
    font-family: "Open Sans", sans-serif;
}
.logo{
    max-width: 150px;
    align-self: center;
}
.logo-login {
    /* margin-top: 100px; */
    max-width: 150px;
    align-self: center;
    align-items: center;
    display: block;
}
.hcs {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.spinner{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.full-height{
    height: 100%;
}
h1.title{
    margin-top: 100px;
    font-size: 25px;
    font-weight: bold;
    color: var(--perizia-card-title-color);
}
ion-input{
    /* border-bottom: 1px solid var(--ion-color-primary); */
    padding: 5px;
    margin-top: 10px;
    width: auto;
    align-self: center;
    font-size: 20px;
}
ion-input > label{
    margin-left: 10px;
}
input{
    font-size: 22px!important;
}
