.thumbs{
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.add-button{
    width: 56px;
    height: 56px;
    background-color: rgb(216, 216, 216);
    --background: red;
    margin-top: 10px;
    margin-bottom: 10px;
}
ion-list-header {
    --background: #f0f0f0;
    --color: rgb(0, 0, 0);

    border-top: 1px solid rgb(197, 197, 197);
    /* --border-width: 0 0 4px 0; */
    /* --border-style: double; */
}
.hidden{
    display: none;
}
.show{
    display: block;
}
