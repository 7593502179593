ion-card{
    margin-top:50px;
    text-align: center;
    align-items: center;
    padding: 15px;
}

h1.title{
    font-size: 25px;
    font-weight: bold;
    color: var(--perizia-card-title-color);
    margin-bottom: 20px;
}

ion-input.form-input {
    --border-width: 0px;
    border-bottom: 1px solid var(--ion-color-primary);
}
ion-input.form-input.has-focus {
    --highlight-color-focused: none;
}

  /* input per ios */
.sc-ion-input-ios-h{
    border-bottom: 1px solid var(--ion-color-primary);
}
