.header-title{
    font-size: 1.5em;
    font-weight: bold;
    color: var(--header-title-color);
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 1.5em;
}
/* ion-header .logo{
    width: 55px;
    height: 55px;

 } */
.text-center{
    text-align: center;
}
.logout-icon{
    font-size: 25px;
    color: var(--logout-icon);
}
